import React, { useState , useEffect , useRef  } from 'react';

import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import { Apartment } from '@mui/icons-material';

import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';


function TypeAntiroutine(elt) {

  const [json, setJson] = useState(elt);
  const [type, setType] = useState(elt.type);
  const [title, setTitle] = useState(elt.title);
  const [index, setIndex] = useState(elt.index);

  const [firstRendered, setFirstRendered] = useState(null);

  const addAfter = (param) => {
    elt.onChange( "ADD_AFTER" , elt.index, null );
  }
  const toUp = () => {
    elt.onChange( "ORDER_TOP" , elt.index, json);
  }

  const toDown = () => {
    elt.onChange( "ORDER_BOTTOM" , elt.index, json);
  }

  const removeThis = () => {
    elt.onChange( "REMOVE" , elt.index, null);
  }

  const onChange = ( champs, index, value) => {

    let tempo;
    switch (champs) {
      case 'title':
        setTitle(value);
        break;
      case 'type':
        setType(value);
        break;
      default :
        return
    }

  }

  useEffect(() => {

    if( firstRendered != null ) setJson({ index: index, type: type, title: title });
    setFirstRendered(1);

  }, [type, title, index]);

  useEffect(() => {
    if (firstRendered != null) {
      /// remonté de la modification vers App parent
      elt.onChange( "EDIT" , elt.index, json);
    }
  }, [json]);

  return (
    <>
      <div className="list-element">
        <Card variant="outlined">
          <TextField id={'index_'+index} label="Index" value={index} onChange={e => {}} />
          <TextField id={'type_'+index} label="Type" value={type} onChange={e => onChange('type', index, e.target.value)} />
          <TextField id={'title_'+index} label="Titre"  value={title} onChange={e => onChange( 'title', index, e.target.value)} />
        </Card>

      </div>
      <div className="list-element-action">
        <ArrowCircleUpIcon onClick={() => toUp()} variant="contained"  />
        <ArrowCircleDownIcon onClick={() => toDown()} variant="contained" />
        <AddCircleIcon onClick={() => addAfter()} variant="contained" />
        <DeleteOutlineIcon onClick={() => removeThis()} variant="contained"  />
      </div>
    </>
  )
}

export default TypeAntiroutine;

import React, { useState , useEffect , useRef  } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import { Apartment } from '@mui/icons-material';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import NativeSelect from '@mui/material/NativeSelect';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

import TypeActusCard from './type_actus_card';

import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SaveIcon from '@mui/icons-material/Save';

import Lottie from 'react-lottie';
import animationData from '../lotties/brain.json'


function TypeActus(elt) {

  const [json, setJson] = useState(elt);
  const [type, setType] = useState(elt.type);
  const [title, setTitle] = useState(elt.title);
  const [message, setMessage] = useState(elt.message);
  const [index, setIndex] = useState(elt.index);
  const [bgColor, setBgcolor] = useState( (elt.backgroundColor)? elt.backgroundColor : '' );
  const [content, setContent] = useState( (elt.content)? elt.content : ''  );

  const [onEdit, setOnEdit] = useState(0);
  const [update, setUpdate] = useState(0);
  const [slideCount, setSlideCount] = useState(elt.content.length);

  const [hasLottie, setHasLottie] = useState(false);
  const [lotties, setLotties] = useState(elt.lotties);
  const [lottie, setLottie] = useState(elt.lottie);

  const [lottieJson , setLottieJson] = useState(false);

  const [config] = useState(elt.config);

  const [handleSave, setHandleSave] = useState(0);

  // console.log(elt);
  // console.log(elt.lotties);
  // console.log(lotties);

  const [firstRendered, setFirstRendered] = useState(null);

  const addAfter = (param) => {
    elt.onChange( "ADD_AFTER" , elt.index, null );
  }
  const toUp = () => {
    elt.onChange( "ORDER_TOP" , elt.index, json);
  }

  const toDown = () => {
    elt.onChange( "ORDER_BOTTOM" , elt.index, json);
  }

  const removeThis = () => {
    elt.onChange( "REMOVE" , elt.index, null);
  }

  const handleEdit = () => {
    setOnEdit((onEdit == 0) ? 1 : 0);
    if (onEdit == 1) {
      if (hasLottie) {
        setJson({ index: index, type: type, title: title , message: message, content: content, backgroundColor: bgColor, lottie: lottie });
      } else {
        setJson({ index: index, type: type, title: title , message: message, content: content, backgroundColor: bgColor });
      }
      setHandleSave(1);
    }
  };

  const onChange = (field, index, value) => {
    console.log(value);
    switch (field) {
      case 'title':
        setTitle(value);
        break;
      case 'type':
        setType(value);
        break;
      case 'bgColor':
        setBgcolor(value);
        break;
      case 'lottie':
        updateLottie(value);
        break;
      case 'cards':
        updateCards(value)
      default :
        return
    }
  }

  const updateCards = (value) => {
    console.log('upadte cards from type_actus.jsx');
    console.log(value);
    if (hasLottie) {
      console.log('cas 1');
      setJson({ index: index, type: type, title: title , message: message, content: value, backgroundColor: bgColor, lottie: lottie });
    } else {
      console.log('cas 2');
      setJson({ index: index, type: type, title: title , message: message, content: value, backgroundColor: bgColor });
    }
  }

  const toggleLottie = (e) => {

    if (e.target.checked) {
      //setJson({ index: index, type: type, title: title , message: message, content: content, backgroundColor: bgColor, lottie: lottie });
      setHasLottie( true );
    } else {
      //setJson({ index: index, type: type, title: title , message: message, content: content, backgroundColor: bgColor });
      //setLottie(null);
      setHasLottie( null );
    }

  }

  const updateLottie = (value) => {
    //setJson({ index: index, type: type, title: title, message: message, content: content, backgroundColor: bgColor, lottie: value });
    setLottie(value);
    loadLottie(value);
  }

  useEffect(() => {
    // console.log("use effect 1");
    // if( firstRendered != null ) setJson({ index: index, type: type, title: title , message: message, content: content, backgroundColor: bgColor });
    setFirstRendered(1);

  }, [type, title, index, bgColor, content]);

  useEffect(() => {
    console.log("use effect 2");
    if (firstRendered != null && handleSave == 1) {
      /// remonté de la modification vers App parent
      //elt.onChange("EDIT", elt.index, json);
      elt.onChange("UPDATE_JSON", elt.index, json);
      setHandleSave(0);
    }
  }, [handleSave]);

  const reOrderCards = (action, index, json) => {

    let newPosition = (action == "TO_LEFT") ? index - 1 : index + 1;

    if (newPosition > -1 && newPosition < content.length) {

      console.log("newposition is valide");
      const elts = content;
      // Delete the item from it's current position
      var item = elts.splice(index, 1);
      // Move the item to its new position
      elts.splice(newPosition, 0, item[0]);
      let newElts = elts;
      // update des indexes
      newElts.map((elt, key) => {
        newElts[key].index = key;
      });
      /// set state and set update state to init dom render
      setContent( newElts );
      onChange('cards', null, content);
      console.log(content);
      setHandleSave(1);
    }

  }
  const removeElt = (index) => {
    if (confirm("Supprimer cet éléments ?")) {
      //setUpdate(1);
      const elts = content;
      elts.splice(index, 1);
      let newElts = elts;
      newElts.map((elt, key) => { newElts[key].index = key; });
      setContent(newElts);
      onChange('cards', null, content);
      setHandleSave(1);
    }
  }

  const addElt = (index) => {
    setDroped( true );
    const newElt = {
      "imgApp": "",
      "accessibility": "",
      "redirectApp": "",
      "campaign_name": ""
    };
    const elts = content;
    elts.splice(index + 1, 0, newElt);
    let newElts = elts;
    newElts.map((elt, key) => { newElts[key].index = key; });
    setContent(newElts);
    console.log(newElts);
    //onChange('cards', null, content);
    //setHandleSave(1);
    setTimeout(function () {
      //setSlideCount(content.length);
      setDroped( false );
    }, 0);

  }

  //// A modifier et à mettre dans un hook useEffect à éxecution unique
  const loadLottie = (lottieFileName) => {

    console.log('----'+lottieFileName);
    //let url = config.host + "/emedias/lotties/"+ lottieFileName + ".json";
    let url = config.chronotoolsGetLottie+'?filename='+lottieFileName + ".json";

    let query = fetch(url).then(response => {
      return response.json();
    }).then(data => {
      setLottieJson(data);
      setHasLottie(true);
    }).catch(err => {
      console.log('erreur fetch');
    });

  }
  if (json.lottie && hasLottie == 0) loadLottie(json.lottie);


  const lottieOptions = {
      loop: true,
      autoplay: true,
      animationData: lottieJson,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
  };

  const [checked, setChecked] = React.useState([true, false]);

  const updateOneCard = (index, json) => {
    alert('updateOneCard');
    let current = content;
    alert(json.campaign_name);
    current[index] = json;
    setContent(current);
    console.log(content);
    onChange('cards', null, current);
    setHandleSave(1);

  }

  //// gestion des cards
  const onChangeCard = ( action, key, json ) => {
    if (action == "TO_LEFT" || action == "TO_RIGHT") reOrderCards(action, key, json);
    else if (action == "REMOVE") {
      removeElt(key);
    } else if (action == "UPDATE") {
      alert('update one card');
      updateOneCard(key, json);
    } else if (action == "ADD") {
      addElt(key);
    }
  }

  var itemToMove = null;
  const [itemDropIn, setItemDropIn] = useState(false);
  const [droped, setDroped] = useState(false);
  ///// gestion drag and drop
  const handleDrag = (e, index) => {
    e.target.classList.add('ondragg');
    lastdragg = e.target;
    itemToMove = index;
  }

  let lastDroped = false;
  let lastdragg = false;
  const drop_handler = (destIndex) => {
    setDroped( true );
    let json = content[itemToMove];

    console.log("index " + itemToMove + " prends la place de " + destIndex);
    console.log(content[itemToMove]);
    // console.log("newposition is valide");
    const elts = content;
    // // Delete the item from it's current position
    elts.splice(itemToMove, 1);
    // // Move the item to its new position
    elts.splice(destIndex, 0, json);
    let newElts = elts;
    // // update des indexes
    newElts.map((elt, key) => {
      newElts[key].index = key;
    });
    // /// set state and set update state to init dom render
    setContent( newElts );
    // onChange('cards', null, content);
    console.log(content);

    // setHandleSave(1);
    setTimeout(() => {
      lastdragg.classList.remove("ondragg");
      setDroped( false );
    }, 0)
  }
  const dragover_handler = (e , index) => {
    console.log('on drop sur :');

    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
    //setItemDropIn(index);
    //document.querySelector('.ondropp').classList.remove('ondropp');
    if (lastDroped != false)
      lastDroped.classList.remove("ondropp");

    if(index != itemToMove ) e.target.classList.add('ondropp');
    lastDroped = e.target;
  }



  return (
    <>
        <div className="list-element" style={{backgroundColor:bgColor}} >

            <Card variant="outlined" style={{ backgroundColor: 'transparent' }} >

              <h1>Coucou Stéphane,<br />les news du mercredi sont là !</h1>
              {(hasLottie == 1)
                ? <div className="lottieArea" ><Lottie options={lottieOptions} height={80} width={80} /></div>
                : ''
              }
          <h2>{content.length} {title}</h2>
          <div className="card-vertical">
               { content.map((content, key) => {
                  return ( <div key={key} index={key}  className="card-vertical-dragger"
                    draggable="true"
                    onDragStart={(e)=>handleDrag(e , key)}
                    onDragEnd={handleDrag}
                    onDragOver={(e)=>dragover_handler(e, key)}
                    onDrop={()=>drop_handler(key)}
                  >

                    {(droped != true ) ? <TypeActusCard myKey={key} key={key} index={key} config={config}  {...content} onChange={(action, key, json) => { onChangeCard(action, key, json) }} />
                          : ''
                    }

                          </div>)
                })
              }
              </div>


            </Card>

        </div>

      <div className="list-element-action">
        <div>
          <div><Chip label={type} size="small" /></div>
          <div>
            {(onEdit == 1) ?
              <>
                <TextField
                  label="Couleur arrière plan"
                  id="filled-size-small"
                  variant="standard"
                  size="small"
                  value={bgColor}
                  onChange={e => onChange( 'bgColor', index, e.target.value)}
                />
                &nbsp;
                <TextField
                  label="Sous titre"
                  id="filled-size-small"
                  variant="standard"
                  size="small"
                  value={title}
                  onChange={e => onChange( 'title', index, e.target.value)}
                />
                <div>
                  <FormControlLabel control={<Checkbox onChange={toggleLottie} checked={hasLottie} />} label="Animation Lottie :" />
                  {(hasLottie)
                    ?
                      <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="demo-simple-select-standard-label" htmlFor="lottie-select" >Fichier lottie :</InputLabel>
                        <NativeSelect
                          defaultValue={lottie}
                          inputProps={{
                            name: 'lottie',
                            id: 'lottie-select',
                          }}
                          //onChange={(e) => loadLottie(e.target.value)}
                        onChange={(e) => onChange('lottie' , index, e.target.value)}
                        >
                        <option value="null">Choisir : {lottie}</option>
                          {lotties.map((item, key) => {
                            return <option key={key} value={item.name}>{item.name}</option>
                          })}
                        </NativeSelect>
                      </FormControl>
                    : ''
                  }

                </div>
              </>
            : ''
            }
          </div>
        </div>
          <div>

            <ArrowCircleUpIcon onClick={() => toUp()} variant="contained"  />
            <ArrowCircleDownIcon onClick={() => toDown()} variant="contained" />
            <AddCircleIcon onClick={() => addAfter()} variant="contained" />
          <DeleteOutlineIcon onClick={() => removeThis()} variant="contained" />
          {(onEdit == 1)
              ?  <SaveIcon onClick={() => handleEdit()} variant="contained" color="green" />
              :  <EditIcon onClick={() => handleEdit()} variant="contained" />
              }
          </div>
      </div>
    </>
  )
}

export default TypeActus;

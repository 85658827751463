import React, { useEffect, useState, useContext, createContext } from 'react';

import config from './components/config'

import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Fab from '@mui/material/Fab';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import DescriptionIcon from '@mui/icons-material/Description';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
/// devices
import TabletMacIcon from '@mui/icons-material/TabletMac';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import DesktopMacIcon from '@mui/icons-material/DesktopMac';
import DownloadIcon from '@mui/icons-material/Download';
import CodeIcon from '@mui/icons-material/Code';

import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';

import logo from './logo.svg';
import './App.css';


/// HP elements
import TypeActus from './components/type_actus';
import TypeAntiroutine from './components/type_antiroutine';
import TypeSante from './components/type_sante';
import TypeRecette from './components/type_recette';
import TypeBanniere from './components/type_banniere';

import Lottie from 'react-lottie';

// import myDatas from './json/starter';



function App() {

  const [data, setData] = useState(null);
  const [dataEdit, setDataEdit] = useState({ starterJson: {} });
  const [dataFilesToLoad] = useState(2);
  const [dataFilesLoaded, setDataFilesLoaded] = useState([]);
  const [update, setUpdate] = useState(0);
  const [dialogAddOpen, setDialogAddOpen] = useState(false);
  const [clickFrom, setClickFrom] = useState(null);
  const [lotties, setLotties] = useState(null);

  const [anchorFileMenu, setAnchorFileMenu] = useState(null);
  const openFileMenu = Boolean(anchorFileMenu);

  const [popinFilesOpen, setPopinFilesOpen] = useState(false);
  const [popinNewFilesOpen, setPopinNewFilesOpen] = useState(false);
  const [jsonsListe, setJsonsListe] = useState(null);
  const [currentJsonFile, setCurrentJsonFile] = useState(null);
  const [newJsonFileName, setNewJsonFileName] = useState(null);
  const [popinSourceOpen, setPopinSourceOpen] = useState(false);
  const [sourceMinified, setSourceMinified] = useState(false);

  const [device, setDevice] = useState('phone');

  const listType = [
    { libelle: 'banniere', type: 'banniere' },
    { libelle: 'santé', type: 'sante' },
    { libelle: 'anti routine', type: 'antiRoutine' },
    { libelle: 'recette', type: 'recette' }
  ]

  console.log('*********************************** render');
  // const starter = fetch(config.host+ "/emedias/ws_starter.json").then(response => {
  //     return response.json();
  //   }).then(data => {
  //     //alert('starter loaded');
  //   }).catch(err => {
  //     console.log('erreur fetch');
  //   });


  const changeValue = (action, index, j) => {

    if (action == "EDIT") {
      setUpdate(1);
      // console.log('change : index:' + index + ' - ' + JSON.stringify(j));
      let newdata = [...data.starterJson];
      //console.log(j);
      newdata[index] = j;
      //console.log(JSON.stringify(test[index]));
      setData({ starterJson: [...newdata] });
      setTimeout(() => {
        setUpdate(0);
      }, 0);
    } else if (action == "ORDER_TOP" || action == "ORDER_BOTTOM" ) {
      orderElt(index, j , action);
    } else if (action == "ADD_AFTER") {
      setDialogAddOpen(true);
      setClickFrom(index);
    } else if (action == "REMOVE") {
      removeElt(index);
    } else if ('UPDATE_JSON') {
      let newdata = [...data.starterJson];
      newdata[index] = j;
      setData({ starterJson: [...newdata] });
    }
  }

  const removeElt = (index) => {
    if (confirm("Supprimer cet éléments ?")) {
      // setUpdate(1);
      const elts = data.starterJson;
      elts.splice(index, 1);
      let newElts = elts;
      newElts.map((elt, key) => { newElts[key].index = key; });
      setData({ starterJson: [...newElts] });
      setTimeout(() => { setUpdate(0); }, 0);
    }
  }

  const orderElt = (index, elt , action) => {

    let newPosition = (action == "ORDER_TOP") ? index - 1 : index + 1;
    console.log("new position : "+newPosition+" "+data.starterJson.length);
    if (newPosition > -1 && newPosition < data.starterJson.length) {
      setUpdate(1);
      console.log("newposition is valide");
      const elts = data.starterJson;
      // Delete the item from it's current position
      var item = elts.splice(index, 1);
      // Move the item to its new position
      elts.splice(newPosition, 0, item[0]);
      let newElts = elts;
      // update des indexes
      newElts.map((elt, key) => {
        newElts[key].index = key;
      });
      /// set state and set update state to init dom render
      setData({ starterJson: [...newElts] });
      setTimeout(() => {
        setUpdate(0);
      }, 0);
    }
  }

  const addItem = (type, indexBefore) => {
    if (type != false) {
      setUpdate(1);
      setDialogAddOpen(false);
      const elts = data.starterJson;
      indexBefore++;
      elts.splice(indexBefore, 0, { "index": "99", "type": type });
      let newElts = elts;
      // update des indexes
      newElts.map((elt, key) => {
        newElts[key].index = key;
      });
      setData({ starterJson: [...newElts] });
      setTimeout(() => {
        setUpdate(0);
      }, 0);
    } else {
      setUpdate(0);
      setDialogAddOpen(false);
    }
  }

  const setDataFromTextArea = (data) => {
    let newElts = JSON.parse(data);
    setData({ starterJson: [...newElts] });
      setTimeout(() => {
        setUpdate(0);
      }, 0);
  }

  const saveJson = (filename , isNewFile) => {
    setUpdate(1);
    setPopinNewFilesOpen(false);
    let filenanmeToSave = (filename!=null) ? filename : currentJsonFile;
    console.log(config.host);

    let url = config.chronotoolsSave+'?filename='+filenanmeToSave;

    let datatosend = new FormData();
    datatosend.append( "json", JSON.stringify( data ) );

    let query = fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type':'application/x-www-form-urlencoded'
      },
      body: 'json=' + encodeURIComponent(JSON.stringify(data))
    }).then(response => {
      return response.text();
    }).then(data => {
      if (data == "success") {
        if (isNewFile == true) { setCurrentJsonFile(filenanmeToSave); }
        setTimeout(function () { setUpdate(0); }, 1000);
      }
    }).catch(err => {
      console.log('erreur fetch');
    });
  }

  const handleOpenFileMenu = () => {
    setAnchorFileMenu(event.currentTarget);
  };
  const handleCloseFileMenu = (param) => {
    setAnchorFileMenu(null);
    if (param == "saveCurrentFile") saveJson(null, false);
    else if (param == "openJsonFile") loadJsonFilesListe();
    else if(param == "newJsonFile") openPopinNewFile();
  };

  const lottieOptions = {
      loop: true,
      autoplay: true,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
  };

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    }),
  };

  //// A modifier et à mettre dans un hook useEffect à éxecution unique
  const loadLottie = (lottieFileName) => {
    console.log('----'+lottieFileName);
    //let url = config.host + "/emedias/lotties/"+ lottieFileName + ".json";
    let url = config.chronotoolsGetLottie+'?filename='+lottieFileName + ".json";

    let query = fetch(url).then(response => {
      return response.json();
    }).then(data => {
      return(data);
    }).catch(err => {
      console.log('erreur fetch');
    });

  }


  const loadJsonFilesListe = () => {

    setPopinFilesOpen(true);

    let query = fetch(config.chronotoolsGetJsonFilesListe).then(response => {
      return response.json();
    }).then(data => {
      setJsonsListe(data);
    }).catch(err => {
      console.log('erreur fetch');
    })

  }

  const openPopinNewFile = () => {
    setPopinNewFilesOpen( (popinNewFilesOpen == false)? true : false );
  }

  const loadJsonFile = (param) => {

    setPopinFilesOpen(false);
    if (param != false) {
      setUpdate(1);
      let toto = fetch(config.chronotoolsGetJson + "?filename=" + currentJsonFile).then(response => {
        return response.json();
      }).then(data => {
        console.log(data);
        setData(data);
        let tab = dataFilesLoaded;
        tab.push("lotties");
        setDataFilesLoaded([...tab]);

        setTimeout(() => {
          setPopinFilesOpen(false);
          setUpdate(0);
        }, 0);


      }).catch(err => {
        console.log('erreur fetch');
      });
    }

  }

  const creatNewJsonFile = (filename) => {
    let jsonType= '{"starterJson":[{"index":0,"type":"actus","title":"","message":"","content":[{"imgApp":"","redirectApp":"","accessibility":"","campaign_name":"","index":0}],"backgroundColor":"#FFF"}]}';
    setData(JSON.parse(jsonType));
    setTimeout(() => {
      saveJson(newJsonFileName , true);
    }, 0);
  }

  const handleChangeDevice = (device) => {
    setDevice(device);
  }

  const handleViewJson = (bool) => {
    ///alert("on affiche le code source json");
    switch (bool) {
      case true:
        setPopinSourceOpen(true);
        setDataEdit(data);
        break;
      case false:
        setPopinSourceOpen(false);
        break;
      case "valid":
        setPopinSourceOpen(false);
        setData(dataEdit);
        setUpdate(1);
        setTimeout(() => {
          setUpdate(0);
        }, 0);
        break;
      case "abort":
        setPopinSourceOpen(false);
        break;
    }
  }

  const editSourceCode = (value) => {
    var source = value;
    setDataEdit({ starterJson: JSON.parse(source) });
  }


  const handleDownloadFile = () => {
    alert('on téléchage le fichier');
  }

  const copyInClipboard = () => {

    navigator.clipboard.writeText( "test" );
    alert("Copié dans le Clipboard !");
  }

  //// chargement des lotties
  useEffect(() => {

    let query = fetch(config.chronotoolsGetLotties).then(response => {
      return response.json();
    }).then(data => {

      setLotties(data.list);
      let tab = dataFilesLoaded;
      tab.push("lotties");
      setDataFilesLoaded([...tab]);

    }).catch(err => {
      console.log('erreur fetch');
    })

  }, []);




  return (
    <>
      {/* modal affichée pour aficher le contenu json JSON */}
      {(dataFilesLoaded.length >= dataFilesToLoad) ?
        <Dialog onClose={() => { }} open={popinSourceOpen} onBackdropClick={()=>setPopinSourceOpen(false)} fullWidth="true" maxWidth="xl" >
          <div className="modal modal-json-viewer">
            <textarea id="sourcecode" name="sourcecode" className="source-area" editable="true"
              rows="50"
              value={ (sourceMinified == false ) ?  JSON.stringify(dataEdit.starterJson, undefined, 4) :   JSON.stringify(dataEdit.starterJson ) }
              onChange={(e) => { editSourceCode(e.target.value) }}
            >
            </textarea>
            <div className="modal modal-actions" >
              <Stack direction="row" spacing={1} alignItems="center">
                <Switch inputProps={{ 'aria-label': 'ant design' }} onChange={(e) => { setSourceMinified(e.target.checked) }} />
                <Typography>Minifié</Typography>
              </Stack>
              <div onClick={copyInClipboard} >Copier</div>
              <ButtonGroup variant="contained" aria-label="outlined button group right">
                <Button variant="outlined" onClick={() => handleViewJson("abort") }>ANNULER</Button>
                <Button variant="" onClick={() => handleViewJson("valid") }>VALIDER</Button>
              </ButtonGroup>
            </div>
          </div>
        </Dialog>
        : null
      }
      {/* modal affichée pour l'ouverture d'un fichier JSON */}
      <Dialog onClose={() => { }} open={popinFilesOpen} >
        <div className="modal modal-explorer">
          <h3>Ouvrir : <small>{currentJsonFile}</small></h3>
          {(jsonsListe!=null)
          ? jsonsListe.map((elt, key) => {
            return <ListItem key={key} onClick={() => setCurrentJsonFile(elt)} className={  (currentJsonFile == elt) ? 'filelist-element active' : 'filelist-element'}  ><DescriptionIcon />&nbsp;&nbsp;{elt}</ListItem>
            })
            : "chargement de la liste..."
          }
          <ButtonGroup variant="contained" aria-label="outlined button group">
            <Button variant="outlined" onClick={() => loadJsonFile(false)}>ANNULER</Button>
            <Button variant="" onClick={() => loadJsonFile() }>VALIDER</Button>
          </ButtonGroup>
        </div>
      </Dialog>
      {/* modal affichée pour la création d'un nouveau JSON */}
      <Dialog onClose={() => { }} open={popinNewFilesOpen} >
        <div className="modal modal-explorer">
          <h3>Nouveau fichier : </h3>
          <TextField id="inputNewJsonFile" label="new file" value={newJsonFileName} onChange={(e) => setNewJsonFileName(e.target.value)} />
          <ButtonGroup variant="contained" aria-label="outlined button group">
            <Button variant="outlined" onClick={() => openPopinNewFile() }>ANNULER</Button>
            <Button variant="" onClick={() => creatNewJsonFile() }>VALIDER</Button>
          </ButtonGroup>
        </div>
      </Dialog>

      {/* modal affichée lors de l'enregistrement d'un fichier JSON */}
      <Dialog onClose={() => { }} open={Boolean(update)} >
        <div className="modal modal-loader">
          <div className="loader">
            <Box sx={{ m: 1, position: 'relative' }}>
                <Fab
                  aria-label="save"
                  color="primary"
                  sx={buttonSx}
                  onClick={()=>{}}
                >
                  {success ? <CheckIcon /> : <SaveIcon />}
                </Fab>
                {Boolean(update) && (
                  <CircularProgress
                    size={68}
                    sx={{
                      color: green[500],
                      position: 'absolute',
                      top: -6,
                      left: -6,
                      zIndex: 1,
                    }}
                  />
              )}

              </Box>
            </div>
           <p>enregistrement...</p>
        </div>
      </Dialog>
      <div className="header-menu" >
        <div className="menu" >
          <div className="logo">

          </div>
          <Button
                  id="basic-button"
                  aria-controls="basic-menu"
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleOpenFileMenu}
                >
                  Fichier
          </Button>

                <Menu
                  id="basic-menu"
                  anchorEl={anchorFileMenu}
                  open={openFileMenu}
                  onClose={handleCloseFileMenu}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                      <MenuItem onClick={()=>handleCloseFileMenu('newJsonFile')}>Nouveau</MenuItem>
                      <MenuItem onClick={() => handleCloseFileMenu("openJsonFile")}>Ouvrir</MenuItem>
                      {(currentJsonFile != null)
                        ? <MenuItem onClick={() => handleCloseFileMenu("saveCurrentFile")}>Enregistrer</MenuItem>
                        : ''
                      }
                </Menu>
            <Button
                  id="edition-button"
                  aria-controls="edition-menu"
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={()=>{}}
                >
                  Edition
            </Button>

        </div>
        <div>
          <Button variant="outlined" onClick={() => handleChangeDevice('desktop')} ><DesktopMacIcon /></Button>
          <Button variant="outlined" onClick={() => handleChangeDevice('tablette')} ><TabletMacIcon /></Button>
          <Button variant="outlined" onClick={() => handleChangeDevice('phone--large')}  ><PhoneAndroidIcon /></Button>
          <Button variant="outlined" onClick={() => handleChangeDevice('phone')}  ><PhoneIphoneIcon /></Button>

        </div>

        {(currentJsonFile != null)
          ? <div>
              {/* <div className="header-menu-filename"><DescriptionIcon />{currentJsonFile}</div> */}
              <Button variant="outlined" /* onClick={() => handleDownloadFile()} */ href={config.chronotoolsDownloadJson + "?filename=" + currentJsonFile} download  >{currentJsonFile}<DownloadIcon /></Button>
              <Button variant="outlined" onClick={() => handleViewJson(true)}  ><CodeIcon /></Button>
            </div>
          : ''
        }


      </div>
      <div className="main" >

      {(dataFilesLoaded.length >= dataFilesToLoad) ?
        <>
          <div className={"screen screen-"+device} >
            {/* {dataFilesToLoad} - {dataFilesLoaded.length} */}

            <Dialog onClose={()=>{}} open={dialogAddOpen} >
                <div className="modal modal-select modal-select--type" >
                  <h3>Ajouter un élément</h3>
                  <List>
                    {listType.map((elt, key) => {
                      return <ListItem key={key} onClick={(type, indexBefore) => { addItem(elt.type, clickFrom) }}  >
                        {elt.libelle}
                      </ListItem>
                      })
                    }
                  </List>
                  <ButtonGroup variant="contained" aria-label="outlined button group">
                    <Button variant="outlined" onClick={() => addItem(false)}>ANNULER</Button>
                  </ButtonGroup>
              </div>
            </Dialog>

            {update == 0 ?
              <>
              {
                data.starterJson.map((elt, key) => {
                  var el;
                  switch (elt.type) {
                    case 'actus':
                      return <TypeActus config={config} key={key} index={elt.index} {...elt} lotties={lotties} onChange={(action, key, json) => { changeValue(action, key, json) }}  />
                      break;
                    case 'recette':
                      return <TypeRecette  key={key} index={elt.index} {...elt} onChange={(action, key, json) => { changeValue(action, key, json) }}  />
                      break;
                    case 'sante':
                      return <TypeSante key={key} index={elt.index} {...elt} onChange={(action, key, json) => { changeValue(action, key, json) }}  />
                      break;
                    case 'banniere':
                      return <TypeBanniere key={key} index={elt.index} {...elt} onChange={(action, key, json) => { changeValue(action, key, json) }}  />
                      break;
                    case 'antiRoutine':
                      return <TypeAntiroutine key={key} index={elt.index} {...elt} onChange={(action,key, json) => { changeValue(action, key, json) }} />;
                      break;
                    default:
                      return'<div>type inconnu</div>'
                  }

                })
              }
              </>
          : '<>update</>'
          }
          </div>
          {/* <div>
            <textarea id="story" name="story"
                rows="20" cols="70"
                value={JSON.stringify(data.starterJson)}
                onChange={(e) => { test(e.target.value) }}
                >
            </textarea>
          </div> */}
        </>
        // : <>Chargement en cours...{dataFilesToLoad} - {dataFilesLoaded.length}</>
        : <div className="home-screen"></div>
      }
      </div>
     </>
  );

}

export default App;

import React, { useState, useEffect, useRef } from 'react';

// import default style
import 'rsuite/dist/rsuite.min.css';
import { Uploader } from 'rsuite';

import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';

import TextField from '@mui/material/TextField';

import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Badge from '@mui/material/Badge';

import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';

function TypeActusCard(elt) {

  // console.log('card');
  // console.log(elt.index);

  const [json, setJson] = useState(elt);
  const [onEdit, setOnEdit] = useState(0);
  const [index, setIndex] = useState(elt.index);
  const [imgApp, setImgApp] = useState(elt.imgApp);
  const [redirectApp, setRedirectApp] = useState(elt.redirectApp);
  const [accessibility, setAccessibility] = useState(elt.accessibility);
  const [campaign_name, setCampaignName] = useState(elt.campaign_name);
  const [listImages, setListImages] = useState([]);

  const [fileToUpload, setFileToUpload] = useState('');

  const [handleEditImg, setHandleEditImg] = useState(false);
  const [onUpload, setOnUpload] = useState(false);

  const [config] = useState(elt.config);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const Input = styled('input')({
    // display: 'none',
  });

  const toLeft = (e) => {
    elt.onChange('TO_LEFT', elt.index, json);
  }

  const toRight = (e) => {
    elt.onChange('TO_RIGHT', elt.index, json);
  }

  const removeThis = (e) => {
    elt.onChange('REMOVE', elt.index, json);
  }

  const handleEdit = (value) => {
    setOnEdit(value);
    if (value == 0) updateElt();
  }

  const addAfter = () => {
    elt.onChange('ADD', elt.index, null);
  }

  const handleClose = () => setHandleEditImg(false);


  const updateElt = () => {
    let newJson = { index: index, campaign_name: campaign_name, imgApp: imgApp, redirectApp: redirectApp };
    console.log(newJson);
    elt.onChange('UPDATE', elt.index, newJson);
  }

  const onChange = (field, value) => {
    switch (field) {
      case 'imgApp':
        setImgApp(value);
        break;
      case 'redirectApp':
        setRedirectApp(value);
        break;
      case 'accessibility':
        setAccessibility(value);
        break;
      case 'campaign_name':
        setCampaignName(value);
        break;
      default :
        return
    }
  }


  const changeImg = (position) => {
    if (position == true) {
      /// changement de la liste des images
      let titi = fetch(config.chronotools ).then(response => {
        return response.json();
      }).then(data => {

        console.log(data);
        setListImages(data);

      }).catch(err => {
        console.log('erreur fetch');
      })


      ///
      setHandleEditImg(true);
    } else {
      setHandleEditImg(false);
    }

  }

  const chooseImgApp = (name) => {
    console.log("click normalement");
    console.log(name);
    setImgApp(name);
  }

  const handleUpload = (e) => {
    setOnUpload((onUpload == true) ? false : true);
    if (onUpload == false) changeImg(true);
  }

  const image = () => {
    return
  }

  return (
    <div className="card-vertical-itemcontainer" >
      <Card className="card-vertical-item" variant="outlined" >

        <div className="card-vertical-edit">
          {(onEdit == 0) ?
          <div className="card-vertical-content">


              <>
                {(imgApp != '') ?
                  <img className="card-vertical-content-image" src={config.chronotools + imgApp} />
                  : <div className="no-image" ><ImageNotSupportedIcon /></div>
                }
              </>
          </div>
           : ''
          }
      </div>
        {(onEdit == 1)
            ?
              <>
                  <div className="" >
                    <TextField
                      label="campaign_name"
                      id={"campaign_name"+elt.index}
                      key={elt.index}
                      variant="standard"
                      size="small"
                      value={campaign_name}
                      onChange={e => onChange( 'campaign_name', e.target.value)}
                    />
                  </div>
                  <div className="" >
                    <img src={config.chronotools + imgApp} height="100" />
                    <EditIcon onClick={(position) => changeImg(true) } variant="contained" />
                    {/* <TextField
                      label="imgApp"
                      id={"imgapp"+elt.index}
                      key={elt.index}
                      variant="standard"
                      size="small"
                      value={imgApp}
                      onChange={e => onChange( 'imgApp', e.target.value)}
                    /> */}
                  </div>
                  <div className="" >
                    <TextField
                      label="redirectApp"
                      id={"redirectApp"+elt.index}
                      key={elt.index}
                      variant="standard"
                      size="small"
                      value={redirectApp}
                      onChange={e => onChange( 'redirectApp', e.target.value)}
                    />
                  </div>
                  <div className="" >
                    <TextField
                      label="accessibility"
                      id={"accessibility"+elt.index}
                      key={elt.index}
                      variant="standard"
                      size="small"
                      value={accessibility}
                      onChange={e => onChange( 'accessibility', e.target.value)}
                    />
                  </div>
              </>

              :''
      }
      </Card>
       <div className="card-vertical-actions">
          {/* <ArrowCircleUpIcon className="toleft" onClick={() => toLeft()} variant="contained"  />
          <ArrowCircleDownIcon className="toright" onClick={() => toRight()} variant="contained" /> */}
          <AddCircleIcon onClick={() => addAfter()} variant="contained" />
          <DeleteOutlineIcon onClick={() => removeThis()} variant="contained" />
          {(onEdit == 1)
              ?  <SaveIcon onClick={() => handleEdit(0)} variant="contained" />
              :  <EditIcon onClick={() => handleEdit(1)} variant="contained" />
              }
        </div>

        <Modal
          open={handleEditImg}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          width="500"
          >
        <Box sx={{ ...style, width: 600 }} >

                {(onUpload == true)
                ?
                    <>
                        <h2>Uploader une image :</h2>
                        <div>Mobile :<br />
                <Uploader listType="picture-text" action={config.chronotoolsUploader+'/upload.php?dir=/depot/Refonte_2021/HP/phone/'} />
                        </div>
                        <div>Tablette :<br />
                <Uploader listType="picture-text" action={config.chronotoolsUploader + '/upload.php?dir=/depot/Refonte_2021/HP/tab/'} />
                        </div>
                        <ButtonGroup disableElevation variant="contained">
                          <Button onClick={handleUpload} >Retour à la liste</Button>
                        </ButtonGroup>
                    </>
                : <>
                    <h2>Choisissez une image :</h2>
                    <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={200}>
                      {listImages.map((item) => (

                        <ImageListItem key={item.name} onClick={() => { chooseImgApp(item.name) }} className={(item.name == imgApp) ? 'imageContainer selected' : 'imageContainer'}  >

                             <img
                              src={config.chronotools+item.name+'?w=164&h=164&fit=crop&auto=format'}
                              srcSet={config.chronotools+item.name+'?w=164&h=164&fit=crop&auto=format&dpr=2 2x'}
                              alt={item.name}
                              loading="lazy"
                            />

                        </ImageListItem>
                  ))}
                    </ImageList>
                    <ButtonGroup disableElevation variant="contained">
                      <Button onClick={handleUpload} >Ajouter une image</Button>
                      <Button onClick={handleClose} >Valider</Button>
                    </ButtonGroup>
                  </>
              }
            </Box>
        </Modal>

    </div>
  )


}

export default TypeActusCard;


/*
campaign_name: "Test comme des chefs"
categoryId: "0P"
title: "Box comme des chefs"
*/

// const appConfig = {
//   host: "http://127.0.0.1:8741",
//   chronotools: "http://127.0.0.1:8741/explore.php/?dir=/depot/Refonte_2021/HP/phone/",
//   chronotoolsUploader: "http://127.0.0.1:8741/upload.php",
//   chronotoolsSave: "http://127.0.0.1:8741/json-save.php",
//   chronotoolsGetJsonFilesListe: "http://127.0.0.1:8741/jsons-get.php",
//   chronotoolsDownloadJson: "http://127.0.0.1:8741/json-download.php",
//   chronotoolsGetJson: "http://127.0.0.1:8741/json-get.php",
//   chronotoolsGetLotties: "http://127.0.0.1:8741/lotties-get.php",
//   chronotoolsGetLottie: "http://127.0.0.1:8741/lottie-get.php"
// }


/// SUR SERVEUR CHRONOTOOLS
// export default appConfig;
// const appConfig = {
//   host: "http://chronotools/Refonte_App/build",
//   chronotools: "http://chronotools/Refonte_App/build/explore.php/?dir=/depot/Refonte_2021/HP/phone/",
//   chronotoolsUploader: "http://chronotools/Refonte_App/build/upload.php",
//   chronotoolsSave: "http://chronotools/Refonte_App/build/json-save.php",
//   chronotoolsGetJsonFilesListe: "http://chronotools/Refonte_App/build/jsons-get.php",
//   chronotoolsDownloadJson: "http://chronotools/Refonte_App/build/json-download.php",
//   chronotoolsGetJson: "http://chronotools/Refonte_App/build/json-get.php",
//   chronotoolsGetLotties: "http://chronotools/Refonte_App/build/lotties-get.php",
//   chronotoolsGetLottie: "http://chronotools/Refonte_App/build/lottie-get.php"
// }


// SUR SERVEUR BIG :
// export default appConfig;
const appConfig = {
  host: "https://chronotools.bigbizyou.fr/",
  chronotools: "https://chronotools.bigbizyou.fr/explore.php/?dir=/depot/Refonte_2021/HP/phone/",
  chronotoolsUploader: "https://chronotools.bigbizyou.fr/upload.php",
  chronotoolsSave: "https://chronotools.bigbizyou.fr/json-save.php",
  chronotoolsGetJsonFilesListe: "https://chronotools.bigbizyou.fr/jsons-get.php",
  chronotoolsDownloadJson: "https://chronotools.bigbizyou.fr/json-download.php",
  chronotoolsGetJson: "https://chronotools.bigbizyou.fr/json-get.php",
  chronotoolsGetLotties: "https://chronotools.bigbizyou.fr/lotties-get.php",
  chronotoolsGetLottie: "https://chronotools.bigbizyou.fr/lottie-get.php"
}

export default appConfig;
